<template>
  <div class="comment-words">
    <div class="item-header">评论分析（评论数量：{{ comments }}）</div>

    <div class="chart-no-data" v-if="commentHotWordsData.length == 0">
      <img src="@/assets/img/datacenter/comment-no-data.png" alt="" />
      <span>数据未产出</span>
      <span>若为首次进入，数据次日起计算产出</span>
    </div>

    <div class="item-chart" v-else>
      <div class="chart-content">
        <div class="header">
          <div class="header-type">
            <span>评论热词 top10</span>
          </div>
        </div>

        <div class="chart" ref="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
//dataCenterCommentHotWords
export default {
  name: "CommentWords", // 组件名称
  data() {
    return {
      flag: true,
      commentHotWordsData: [],
      comments: 0,
    };
  },
  props: {
    id: { type: String | Number, Required: true },
  },
  mounted() {
    this.getCommentHotWords();
  },
  watch: {
    id: {
      handler(newVal) {
        this.getCommentHotWords();
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    // 计算属性
  },
  methods: {
    getCommentHotWords() {
      const params = {
        id: this.id,
      };
      this.$httpStudent.axiosGetBy(this.$api.dataCenterCommentHotWords, params, (res) => {
        if (res.code === 200) {
          this.comments = res.data.comments;

          this.commentHotWordsData = []; // 重置
          let wordsData = res.data.words;
          let countData = res.data.count;
          wordsData.forEach((item, index) => {
            this.commentHotWordsData.push({
              name: item,
              value: Number(countData[index].replace("%", "")),
            });
          });
          this.$nextTick(() => this.setChartOptions());
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    setChartOptions() {
      if (!this.$refs.chart) return;

      const data = this.commentHotWordsData.slice(0, 10);
      const chart = this.$echarts.init(this.$refs.chart);
      const options = {
        grid: {
          left: "0%",
          right: "1%",
          top: "10%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis", // 悬停时触发
          axisPointer: { type: "shadow" }, // 指示器类型为阴影
          formatter: (params) => {
            return `
              <div style="color: '#51515C';">${params[0].name}</div>
              <div style="color: #000;"> <i style="display: inline-block; width: 10px; height: 10px; background-color: ${params[0].color}; margin-right: 10px; border-radius: 50%;"></i><span style="color: #292A37; font-weight: bold; font-size: 14px; font">${params[0].value}%</span></div>
            `;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.name),
          axisLabel: {
            color: "#86909C",
            formatter: function (value) {
              // 返回字符串的最后五个字符
              return value.length > 5 ? value.slice(-5) : value;
            },
          },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "86909C", formatter: "{value}%" },
          axisLine: { show: false, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: this.getTrendLabel,
            type: "bar",
            showSymbol: false,
            smooth: true,
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
              borderRadius: [4, 4, 0, 0],
            },
            barWidth: 20,
          },
        ],
      };
      chart.clear();
      chart.setOption(options);

      window.addEventListener("resize", () => chart.resize());
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-words {
  .item-header {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    text-align: left;
    font-style: normal;
  }

  .item-chart {
    width: 100%;
    min-height: calc(100vh - 490px);
    box-sizing: border-box;
    background-color: #fff;
    padding-top: 24px;

    .chart-content {
      height: 320px;
      background-color: #f7f9fb;
      border-radius: 8px;
      padding: 24px;
      .header {
        display: flex;
        align-items: center;
        position: relative;

        &-type {
          margin-right: 10px;
          span {
            margin-right: 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #777e87;
            line-height: 14px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }

    .chart {
      height: 300px;
      margin-top: 10px;
      // border: 1px solid black;
    }
  }

  .chart-no-data {
    width: 100%;
    min-height: calc(100vh - 490px);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: rgb(201, 202, 202);
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
